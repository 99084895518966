.test {

    &__question-wr {
        margin-bottom: .5rem;
    }

    &__question-wr--boolean {
        display: flex;
    }

    &__question {
        @include grey-border;
        padding: .3rem .4rem;
        flex-grow: 1;
    }

    &__question--grey-bg {
        background-color: $grey-border-80;
    }

    &__answer {
        @include grey-border-off-tb;
        padding: .3rem .4rem;
    }

    &__answer .form-check:hover {
        background-color: $light-grey;
    }

    &__answer--boolean {
        padding: 0;
        border-left: none;
        border-top: 1px solid $grey-border;
    }

    &__error {
        background-color: $wrong;
    }

    &__success {
        background-color: $right;
    }

    &__result .btn {
        margin-top: .5rem;
    }

    &__res-title {
        border: 1px solid $grey-border;
        border-radius: 1px;
        padding: 12px;
        font-weight: 700;
        color: $font-color;
        word-break: break-word;
    }

    &__block-label {
        display: block;
    }
}

@media (max-width: $md) {
    .test__res-title {
        padding: 13px;
    }

    .test__result-collapse {
        padding-right: 0;


        & a[role="button"] {
            min-height: 50px;
        }
    }

    .test__category-result > .col:first-child {
        width: 58%;
    }

    .test__category-result > .col:nth-child(2) {
        width: 42%;
    }
}


.marker {
    font-size: 0.8rem;
    padding: 0.2em 0.3em;
    color: $white;
    border-radius: 0.25rem;
    display: inline-block;
    margin-left: 0.8rem;
    vertical-align: top;
}

.marker--paid {
    background-color: $marker-paid;
}

.marker--free {
    background-color: $l1;
}

.col-count-3 {
    column-count: 3;
    column-gap: 4%;
}

@media (max-width: $lg){
    .col-count-md-2 {
        column-count: 2;
        column-gap: 5%;
    }
}

@media (max-width: $sm){
    .col-count-sm-1 {
        column-count: 1;
        column-gap: 5%;
    }
}

.h3, h3 {
    font-size: calc(1rem + 0.5vw);
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

@media (min-width: $xl) {
    .h3, h3 {
        font-size: 1.2rem;
    }
}

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap');

// Variables
@import '_variables.scss';
@import 'mixin.scss';

// Bootstrap
/* @import '~bootstrap/scss/bootstrap';*/
@import 'body/body.scss';
@import 'text/h1.scss';
@import 'text/h2.scss';
@import 'text/h3.scss';
@import 'text/font.scss';
@import 'text/a.scss';
@import 'text/text-align.scss';
@import 'text/service-info.scss';
@import 'text/first-letter.scss';
@import 'text/white-space.scss';
@import 'contenteditable/contenteditable.scss';

@import 'container/container.scss';
@import 'container/container-content.scss';
@import 'bg/bg-footer.scss';
@import 'margin/margin.scss';
@import 'margin/mr-auto.scss';
@import 'margin/ml-auto.scss';
@import 'padding/padding.scss';
@import 'navbar/navbar.scss';
@import 'navbar/drop-down.scss';
@import 'card/card.scss';
@import 'col-count/col-count.scss';
@import 'img/img-w.scss';
@import 'img/img-h.scss';
@import 'border/border.scss';
@import 'flex/flex.scss';
@import 'grid/d-grid.scss';
@import 'pagination/pagination.scss';
@import 'input/input.scss';
@import 'buttons/button.scss';
@import 'buttons/close.scss';
@import 'accordion/accordion.scss';
@import 'alert/alert.scss';
@import 'list/list.scss';

@import 'test/test.scss';
@import 'check/check.scss';
@import 'progress/progress-test.scss';
@import 'progress/header-scroll.scss';
@import 'stage/stage.scss';
@import 'scale/scale.scss';
@import 'code/code.scss';
@import 'job/job.scss';

@import 'user-profile/user-profile.scss';
@import 'input/search.scss';
@import 'input/password.scss';
@import 'input/select.scss';
@import 'form-control/form-control.scss';
@import 'table/dataTables.scss';
@import 'table/table.scss';
@import 'width/width.scss';
@import 'height/height.scss';
@import 'message/message.scss';
@import 'modal/modal.scss';

@import 'accordion/accordion.scss';
@import 'alphabet/alhpabet.scss';
@import 'diagramm/diagramm.scss';
@import 'box-shadow/box-shadow.scss';
@import 'animation/animation.scss';
@import 'v-cloak/v-cloak.scss';
@import 'marker/marker.scss';
@import 'position/position.scss';


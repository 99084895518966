.width-full {
    width: 100%;
}

.width-md-50 {
    width: 50%;
}

@media (max-width: $md) {
    .width-md-50 {
        width: 100%;
    }
}

/*стили для иконок на главной*/
.img-icon-fix {
    height: 69px;
}

.img-icon-fix--small {
    height: 61px;
    padding-top: 8px;
    margin-bottom: 6px
}

.v-cloak-container, [v-cloak] {
    display: none;
}

[v-cloak] + .v-cloak-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
}
.v-cloak-img {
    width: 100px;
    transform: rotate(360grad);
    animation: rotate 0.6s infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(1turn);
    }
}

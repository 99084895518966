@mixin grey-border {
    border: 1px solid $grey-border;
    border-radius: 1px;
}

@mixin grey-border-off-tb {
    border-bottom: 1px solid $grey-border;
    border-right: 1px solid $grey-border;
    border-left: 1px solid $grey-border;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
}

@mixin flex-sp-between-a-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin flex-a-center {
    display: flex;
    align-items: center;
}

@mixin flex-col-a-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin flex-wrap {
    flex-wrap: wrap;
}

@mixin border-1px {
    border-width: 1px;
    border-style: solid;
}

@mixin border-right-1px {
    border-right-width: 1px;
    border-right-style: solid;
}

@mixin border-bottom-1px {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

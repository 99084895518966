.d-grid {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 10px;

    &--users-admin-three-input {
        grid-template-columns: 150px 240px 1fr;
    }

    &--users-admin-two-input {
        grid-template-columns: 150px 1fr;
    }

    &--admin-date {
        grid-template-columns: 55px 170px 75px 170px 1fr;
        align-items: center;
        grid-column-gap: 10px;
    }

    &--admin-search-export {
        grid-template-columns: 170px 1fr;
        grid-column-gap: 10px;
    }

    &--two-column {
        grid-template-columns: 1fr 1fr;
    }
}

.grid-elem--col-start-5 {
    grid-column-start: 5;
}

@media (max-device-width: $md) {
    .d-grid {
        display: grid;
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        &--users-admin-three-input {
            grid-template-columns: 150px 240px;
        }

        &--users-admin-two-input {
            grid-template-columns: 150px 1fr;
        }

        &--admin-date {
            grid-template-columns: 65px 170px;
            align-items: baseline;
            grid-column-gap: 10px;
        }
    }
}

@media (max-device-width: $sm) {
    .d-grid {
        display: grid;
        grid-column-gap: 10px;
        grid-row-gap: 10px;

        &--users-admin-three-input,
        &--users-admin-two-input {
            grid-template-columns: 1fr;
        }
    }
}

.job {
    margin-bottom: 0;
}

.job__item {
    margin-bottom: .4rem;
    break-inside: avoid-column;
}

.job__link {
    color: $font-color;
    text-decoration: none;
    display: block;

    &:hover {
        text-decoration: underline;
        color: $font-color;
    }

    & span {
        font-weight: bold;
    }
}

.img-w {
    width: 80%;
}

.img-w-60 {
    width: 60%;
}

.img-w-8 {
    width: 8%;
}

.img-w-15 {
    width: 15%;
}

.img-w-18 {
    width: 18%;
    max-width: 250px;
}

.img-w-60 {
    width: 60%;
}

.img-w-80 {
    width: 80%;
}

.img-w-lg-80 {
    width: 80%;
}

@media (max-width: $sm) {
    img.img-w, .img-w-60 {
        width: 30%;
    }

    .img-w-lg-80 {
        width: 50%;
    }

    .img-w-18 {
        width: 58%;
    }
}

.alphabet-list {
    list-style: none;
    margin-top: 2rem;
    padding-left: 0;
}

.alphabet-symbol {

    &:hover {
        color: $blue;
    }

    &--active {
        background-color: $grey-border-80;
    }
}

.copy-link-mess {
    border-radius: .25rem;
    background-color:$l4;
    border-color: $l3;
    color: $font-color;
    position: fixed;
    padding: .375rem .75rem;
    z-index: 99;
    bottom: 10%;
    left: 50%;
    margin-left: -120px;
    display: none;
    vertical-align: middle;
}

.header-scroll {
    position: fixed;
    top: 0 !important;
    left: 0;
    z-index: 1;
    width: 100%;
    background-color: #f1f1f1;
}

.progress-container {
    width: 100%;
    height: 8px;
    background: #ccc;
}

.progress-bar {
    height: 8px;
    background: #1085BF;
    width: 0;
}

.font-bold {
    font-weight: bold;
}

.font-size-sm {
    font-size: .9rem;
}

.big-1 { font-size: 2.5rem;}

.big-2 { font-size: 2rem;}

.big-3 { font-size: 1.8rem;}

.big-4 { font-size: 1.5rem;}

.big-5 { font-size: 1.3rem;}

.big-6 { font-size: 1.2rem;}

$padding-point: .25rem;

@function padding-width($n) {
    @return $n * $padding-point;
}

.pl-1 {
    padding-left: padding-width(1) !important;
}

.pl-2 {
    padding-left: padding-width(2) !important;
}

.pl-3 {
    padding-left: padding-width(3) !important;
}

.pl-lg-3 {
    padding-left: padding-width(3);
}

.pl-4 {
    padding-left: padding-width(4) !important;
}

.pl-5 {
    padding-left: padding-width(5) !important;
}

.pl-6 {
    padding-left: padding-width(6) !important;
}

.pr-1 {
    padding-right: padding-width(1) !important;
}

.pr-2 {
    padding-right: padding-width(2) !important;
}

.pr-3 {
    padding-right: padding-width(3) !important;
}

.pr-4 {
    padding-right: padding-width(4) !important;
}

.pr-5 {
    padding-right: padding-width(5) !important;
}

.pr-6 {
    padding-right: padding-width(6) !important;
}

@media (max-device-width: $md) {
    .pl-lg-3 {
        padding-left: 0;
    }

    .p-5 {
        padding: padding-width(4) !important;
    }
}

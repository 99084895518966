.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-center-lg {
    text-align: center;
}

@media (max-width: $md) {
    .text-md-left {
        text-align: left;
    }

    .text-md-right {
        text-align: right;
    }

    .text-md-center {
        text-align: center;
    }

    .text-center-lg {
        text-align: left;
    }
}

/*блок, в котором находится поиск из коробки*/
.dataTables_filter {
    display: none;
}

.search-wr {
    width: content-box;
    display: flex;

    &::after {
        content: '';
        background: $blue;
        background-image: url("../img/search.svg");
        background-repeat: no-repeat;
        background-position: center;
        width: 49px;
        height: 38px;
    }
}

.search {
    border: 1px solid $grey-border;
    padding: 5px 20px 5px 10px;
    border-radius: 2px;
    width: 232px;

    &:focus {
        color: #212529;
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
       /* box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);*/
    }
}

.search-input {
    position: relative;

    & > span {
        border: 0;
        position: absolute;
        width: 20px;
        height: 20px;
        margin: 0 0 0 5px;
        top: 20px;
        right: 45px;
        border-radius: 50%;
        line-height: 0;
        pointer-events: none;
        cursor: pointer;
        opacity: 0;
        transition: opacity 150ms ease-in-out;
        font-size: 20px;
    }
}

.search-input > input.has-value + span {
    pointer-events: all;
    opacity: 1;
}

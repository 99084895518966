.password-control {
    position: absolute;
    top: 5px;
    right: 20px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../img/view.svg') 0 0 no-repeat;
}

.password-control.view {
    background: url('../img/no-view.svg') 0 0 no-repeat;
}

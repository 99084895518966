html {
    height: 100%;
}

body {
    font-family: 'OpenSans', sans-serif;
}

.body {
    display: flex;
    flex-direction: column;
    height: 100%;

    & main {
        flex-grow: 1;
    }

    &--xl {
        min-width: 1024px;
    }

    &--lg {
        min-width: 992px;
    }
}

p {
    margin-bottom: 0.5rem;
}

@media (max-width: $md) {
    body {
        font-size: 14px;
    }
}

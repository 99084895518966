.code__wr {
    background-color: $l7;
}

.code {
    @include flex-a-center;

    &__label {
        font-weight: bold;
        margin-right: 30px;
    }

    &__label:not(:first-child) {
        margin-left: 30px;
    }

    &__num {
        font-weight: bold;
        font-size: 2.3rem;

        padding: 0 .7rem;
        background-color: $l4;
        margin-right: .3rem;
        margin-bottom: 0.4rem;
        /*box-shadow: 3px 3px 0px #114E00;*/
    }

    &__num:last-child {
        margin-right: 0;
    }

    &.btn {
        padding: 0;

        & .code__num {
            margin-bottom: 0;
        }
    }

    &__active .code__num,
    &.btn:hover .code__num {
        background-color: $light-yellow;
    }
}

@media (max-width: $sm) {
    .code {
        &__num {
            font-size: 1.6rem;
        }
    }
}


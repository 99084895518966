.user-profile {
    &__photo {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        border: 1px solid #ececec;
        margin-left: auto;
    }

    &__img-button .col {
        text-align: left;
    }
}

@media (max-width: $md) {
    .user-profile {
        &__photo {
            margin: 0 auto;
        }

        &__img-button .col {
            text-align: center;
        }
    }
}

.progress-test {
    display: flex;
    align-items: center;

    &__bar {
        height: 50px;
        min-width: 0;
        max-width: 94%;
        color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }

    &__point {
        font-size: 1.3rem;
        font-weight: bold;
        margin-left: 2%;
    }
}

.lv-1 {
    background-color: $l1;
}

.lv-2 {
    background-color: $l2;
}

.lv-3 {
    background-color: $l3;
}

.lv-4 {
    background-color: $l4;
}

.lv-5 {
    background-color: $l5;
}

.lv-6 {
    background-color: $l6;
}

.lv-7 {
    background-color: $l7;
}


.flex-grow {
    flex-grow: 1;
}

.flex-row-to-col {
    display: flex;
    flex-direction: row;
}

@media (max-width: $md) {
    .flex-md-wrap {
        flex-wrap: wrap;
    }

    .flex-row-to-col {
        flex-direction: column;
    }
}

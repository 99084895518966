.dataTables_wrapper {
    position: static;
    overflow: auto;
}

/*сортировка*/
table.dataTable thead .sorting div {
    display: flex;
    align-items: center;
    line-height: 1rem;

    &::after {
        content: '';
        background-image: url("../images/sort_both.png");
        width: 17px;
        height: 19px;
    }
}

table.dataTable thead .sorting_asc div {
    &::after {
        content: '';
        background-image: url("../images/sort_asc.png");
    }
}

table.dataTable thead .sorting_desc div {
    &::after {
        content: '';
        background-image: url("../images/sort_desc.png");
    }
}

table.dataTable thead .sorting_asc_disabled div {
    &::after {
        content: '';
        background-image: url("../images/sort_asc_disabled.png");
    }
}

table.dataTable thead .sorting_desc_disabled div {
    &::after {
        background-image: url("../images/sort_desc_disabled.png");
    }
}
/*наведение строк*/
.table-hover>tbody>tr:hover {
    background-color: $l7;
}


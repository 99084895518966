.dropdown-menu {
    max-width: 300px;
}

.dropdown-item__user-name {
    padding: .25rem 1rem;
    border-bottom: 1px solid $grey-border;
}

.dropdown__name,
.dropdown__email {
    margin-bottom: .2rem;
    word-wrap: break-word;
}

.dropdown__name {
    font-weight: bold;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: $blue;
}

.dropdown-menu .dropdown-item__user-name{
    display:block;
}

@media (max-width: $lg) {
    nav .dropdown-menu {
        display: block;
        border: none;
    }

    nav .dropdown-menu .dropdown-item__user-name{
        display:none;
    }
}

@media (min-width: $menu-sm-400) and (max-width: $lg) {
    nav .dropdown-menu {
        max-width: 100%;
    }
}

@media (max-width: $md) {
    nav .dropdown-menu {
        font-size: $font-md;
    }
}

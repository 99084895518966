.panel {
    margin-bottom: 0;
}

.panel-default > .panel-heading {
    color: #333;
    background-color: #fff;
    border-color: #e4e5e7;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.panel-title {
    @include grey-border;
    font-size: 1rem;
    margin-bottom: 0;
}

.panel-triangle > .panel-heading .panel-title,
.panel-triangle-before > .panel-heading .panel-title,
.panel-triangle-before > .panel-collapse {
    border: none;
}

.panel-default > .panel-heading a {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;

    font-weight: 700;
    color: $font-color;
    text-decoration: none;
}

.panel-triangle-before > .panel-heading a {
    font-weight: normal;
    line-height: 1.2;
}

.panel-default > .panel-heading a:after {
    content: "";
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    margin-left: 5px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    float: right;
    transition: transform .25s linear;
    -webkit-transition: -webkit-transform .25s linear;
}

.panel-default > .panel-heading a[aria-expanded="true"] {
    background-color: #eee;
}

.panel-default > .panel-heading a[aria-expanded="true"]:after {
    content: "\2212";
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.panel-default > .panel-heading a[aria-expanded="false"]:after {
    content: "\002b";
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.panel-triangle > .panel-heading a {
    display: flex;
    align-items: center;
    padding: 10px 0 0 0;
}

.panel-triangle-before > .panel-heading a {
    display: flex;
    align-items: flex-start;
    padding: 10px 0 0 0;
}

.panel-triangle > .panel-heading a:after,
.panel-triangle-before > .panel-heading a:before {
    content: "";
    font-size: 30px;
    line-height: 20px;
}

.panel-triangle > .panel-heading a[aria-expanded="true"],
.panel-triangle-before > .panel-heading a[aria-expanded="true"] {
    background-color: white;
}

.panel-triangle > .panel-heading a[aria-expanded="true"]:after {
    content: "\2023";
    -webkit-transform: rotate(180deg);
    transform: rotate(270deg);
}

.panel-triangle > .panel-heading a[aria-expanded="false"]:after {
    content: "\2023";
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.panel-triangle-before > .panel-heading a[aria-expanded="true"]:after {
    content: "";
}

.panel-triangle-before > .panel-heading a[aria-expanded="true"]:before {
    content: "\2023";
    -webkit-transform: rotate(180deg);
    transform: rotate(270deg);
}

.panel-triangle-before > .panel-heading a[aria-expanded="false"]:after {
    content: "";
}

.panel-triangle-before > .panel-heading a[aria-expanded="false"]:before {
    content: "\2023";
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.panel-collapse {
    padding: .5rem;
    @include grey-border-off-tb;
}

.panel-column-2 > .panel-collapse {
    border: none;
    padding: 0;

    & > .panel-body {
       /* column-count: 2;
        column-gap: 7px;*/
        display: grid;
        grid-column-gap: 30px;
        grid-row-gap: 0;
        grid-template-columns: 1fr 1fr;

        & .panel {
        /*    break-inside: avoid;
            page-break-inside: avoid;*/
        }
    }
}

.accordion-option {
    width: 100%;
    float: left;
    clear: both;
    margin: 15px 0;
}

.accordion-option .title {
    font-size: 20px;
    font-weight: bold;
    float: left;
    padding: 0;
    margin: 0;
}

.accordion-option .toggle-accordion {
    float: right;
    font-size: 16px;
    color: #6a6c6f;
}

.accordion-option .toggle-accordion:before {
    content: "Expand All";
}

.accordion-option .toggle-accordion.active:before {
    content: "Collapse All";
}

@media (max-width: $md) {
    .panel-title {
        font-size: 14px;
    }

    .panel-default > .panel-heading a {
        padding: 9px 8px;
        word-break: break-word;
    }

    .panel-default > .panel-heading a:after {
        font-size: 16px;
    }

    .panel-column-2 > .panel-collapse {
        border: none;
        padding: 0;

        & > .panel-body {
            /* column-count: 2;
             column-gap: 7px;*/
            display: grid;
            grid-column-gap: 10px;
            grid-row-gap: 0;
            grid-template-columns: 1fr;

            & .panel {
                /*    break-inside: avoid;
                    page-break-inside: avoid;*/
            }
        }
    }
}

.stages {
    @include flex-sp-between-a-center;
}

.stage {
    color: $blue;
    border-top: 1px solid $blue;
    border-bottom: 1px solid $blue;
    cursor: pointer;
    width: 18%;
    padding: .3rem .4rem;

    @include flex-col-a-center;
    position: relative;

    &--corner:before {
        content: "";
        background: url("../img/right.svg") top left no-repeat;
        position: absolute;
        top: -1px;
        left: 0;
        width: 20.5px;
        height: 104%;
        background-size: cover;
    }

    &--corner:after {
        content: "";
        background: url("../img/right.svg") top left no-repeat;
        position: absolute;
        top: -1px;
        right: -20px;
        width: 20.5px;
        height: 104%;
        background-size: cover;
    }

    &--active {
        background-color: $blue;
        color: $white;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -1px;

            border-top: 29px solid transparent;
            border-left: 20px solid white;
            border-bottom: 29px solid transparent;
        }

        &:after {
            content: "";
            border-top: 29px solid transparent;
            border-left: 20px solid $blue;
            border-bottom: 30px solid transparent;

            position: absolute;
            top: -1px;
            right: -20px;
        }
    }

    &--pseudo-active {
        background-color: $blue-dark;
        color: $white;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -1px;

            border-top: 29px solid transparent;
            border-left: 20px solid white;
            border-bottom: 29px solid transparent;
        }

        &:after {
            content: "";
            border-top: 29px solid transparent;
            border-left: 20px solid $blue-dark;
            border-bottom: 30px solid transparent;

            position: absolute;
            top: -1px;
            right: -20px;
        }
    }

    &--disabled {
        background-color: $blue;
        color: $white;
        opacity: 0.4;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;

            border-top: 29px solid transparent;
            border-left: 20px solid white;
            border-bottom: 29px solid transparent;
        }

        &:after {
            content: "";
            border-top: 29px solid transparent;
            border-left: 20px solid $blue;
            border-bottom: 30px solid transparent;
            position: absolute;
            top: -1px;
            right: -20px;
        }
    }

    &--wrong {
        background-color: $red-dark;
        color: $white;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;

            border-top: 29px solid transparent;
            border-left: 20px solid white;
            border-bottom: 29px solid transparent;
        }

        &:after {
            content: "";
            border-top: 29px solid transparent;
            border-left: 20px solid $red-dark;
            border-bottom: 30px solid transparent;
            position: absolute;
            top: -1px;
            right: -20px;
        }
    }


    &--sub {
        width: 15.6%;
        padding-left: 15px;
        font-size: 14px;

        &:before {
            content: "";
            background: url("../img/right.svg") top left no-repeat;
            position: absolute;
            top: -1px;
            left: 0;
            width: 11px;
            height: 103%;
            background-size: cover;
        }

        &:after {
            content: "";
            background: url("../img/right.svg") top left no-repeat;
            position: absolute;
            top: -1px;
            right: -11px;
            width: 11px;
            height: 103%;
            background-size: cover;
        }

        &.stage--active:before {
            border-top: 17px solid transparent;
            border-left: 11px solid white;
            border-bottom: 17px solid transparent;
            top: -2px;
        }

        &.stage--active:after {
            border-top: 17px solid transparent;
            border-left: 11px solid $blue;
            border-bottom: 16px solid transparent;
            right: -11px;
        }

        &.stage--pseudo-active:before {
            border-top: 17px solid transparent;
            border-left: 11px solid white;
            border-bottom: 17px solid transparent;
            top: -2px;
        }

        &.stage--pseudo-active:after {
            border-top: 17px solid transparent;
            border-left: 11px solid $blue-dark;
            border-bottom: 16px solid transparent;
            right: -11px;
        }

        &.stage--disabled:before {
            border-top: 17px solid transparent;
            border-left: 11px solid white;
            border-bottom: 17px solid transparent;
            top: -2px;
        }

        &.stage--disabled:after {
            border-top: 17px solid transparent;
            border-left: 11px solid $blue;
            border-bottom: 16px solid transparent;
            right: -11px;
        }
    }

    &__abbr {
        display: none;
    }
}


@media (max-width: $lg) {
    .stage {
        width: 18%;
        padding-left: 15px;

        &--corner:before {
            height: 103%;
        }

        &--corner:after {
            right: -19px;
            width: 20.5px;
            height: 103%;
        }

        &--active:after,
        &--disabled:after {
            border-bottom: 27px solid transparent;
        }
    }

    .stage__description {
        font-size: 13px;
    }

    .stage--sub {
        width: 14.5%;
        font-size: 1rem;

        & .stage__title {
            display: none;
        }

        & .stage__abbr {
            display: block;
        }

        &.stage--active:before,
        &.stage--disabled:before {
            top: 0;
        }
    }
}

@media (max-width: $md) {
    .stage {
        width: 16%;
        /*padding-left: 18px;*/

        &--corner:before {
            height: 103%;
            width: 11px;
        }

        &--corner:after {
            right: -11px;
            width: 11px;
            height: 106%;
        }

        &--active:before,
        &--disabled:before {
            border-top: 15px solid transparent;
            border-left: 11px solid white;
            border-bottom: 16px solid transparent;
        }

        &--active:after,
        &--disabled:after {
            border-top: 15px solid transparent;
            border-left: 11px solid $blue;
            border-bottom: 17px solid transparent;
            right: -11px;
        }

        &__abbr {
            display: block;
        }

        &__title {
            display: none;
        }

        &__description {
            display: none;
        }
    }

    .stage--sub {
        width: 13%;
    }
}

@media (max-width: $sm) {
    .stage--sub {
        width: 13%;

        &.stage--active {
            border-right: 1px solid #1085BF;
        }

        &.stage--active:before {
            left: -1px;
            top: -1px;
        }

        &.stage--active:after {
            border-top: 17px solid transparent;
            border-left: 11px solid $blue;
            border-bottom: 16px solid transparent;
            right: -12px;
        }

        &.stage--disabled {
            border-right: 1px solid #1085BF;
        }

        &.stage--disabled:before {
            border-top: 17px solid transparent;
            border-left: 11px solid white;
            border-bottom: 17px solid transparent;
            top: -1px;
        }

        &.stage--disabled:after {
            border-top: 17px solid transparent;
            border-left: 11px solid $blue;
            border-bottom: 16px solid transparent;
            right: -12px;
        }

    }
}

.btn {
    min-width: 80px;
}

.btn--flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn--icon {
    min-width: auto;
    background-color: transparent;
    padding: .15rem .45rem;
    line-height: 1.2;
}

.btn-link {
    color: $links;

    &:hover {
        color: $links-hover;
    }
}

.btn--nopadding {
    line-height: 1.0;
    padding: 0 3px;
}

.btn--search {
    border-radius: 0 0.25rem 0.25rem 0;
    padding-right: 6px;
    min-width: auto;
}

.btn-primary {
    background-color: $blue;
    border-color: $blue-dark;

    &:hover {
        background-color: $blue-dark;
    }
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: $blue;
    border-color: #0a58ca;
    box-shadow: 0 0 0 .25rem rgba(49, 132, 253, .5);
}

.btn-access {
    width: 25px;
    height: 25px;
    background: url("../img/access.svg") center no-repeat;
    min-width: 25px;
    padding: 5px;
    margin: 0 auto;
}

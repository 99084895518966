// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'OpenSans', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$line-height-table: 1.3;

// Colors
$blue: #1085BF;

$blue-dark: #0a6d9e;
$light-red: rgba(253, 90, 62, 0.52);
$red: #FD5A3E;
$red-dark: #c4452e;
$light-grey: #f5f5f5;
$middle-grey: #c9c9c9;
$violet: #BD95AA;
$light-yellow: #fbeb8b;

$font-color: #1C1C1C;
$links: #1C82B5;
$links-hover: #155D82;
$nav-links: rgba(0,0,0,.55);
$nav-links-hover: rgba(0, 0, 0, .7);
$grey-border: #ececec;
$grey-border-80: rgba(236, 236, 236, 0.8);
$dark-grey-border: rgb(139 139 139 / 60%);
$marker-paid: #4591b8;

$white: white;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$right: #DFF0D8;
$right-border: #1A6405;
$wrong: #F8D7DA;

/*Color progress bar*/
$l1: #97CC64;
$l2: #A7D47D;
$l3: #B7DC94;
$l4: #C6E3AA;
$l5: #D6EBC3;
$l6: #E6F2DA;
$l7: #EFF7E7;

/*diagramm colors*/
$color1: #97CC64;
$color2: #FFD963;
$color3: #FD5A3E;
$color4: #d17905;
$color5: #77B6E7;
$color6: #A955B8;
$color7: #c7c6c3;
$color8: #C1E0E7;
$color9: #DED49E;

//Views
$xxl: 1400px;
$xl: 1200px;
$lg: 991px;
$md: 768px;
$sm: 575px;

$menu-sm-400: 400px;
$font-mobile-view: 320px;

//Fonts
$font-md: 14px;


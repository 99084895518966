[contenteditable="true"]:focus {
    padding-left: 5px;
    padding-right: 5px;
    font-style: italic;
}

.contenteditable-wizywig {
    padding: 0.25rem;
    border: 1px solid $font-color;
    height: 350px;
    overflow-y: auto;

    &--h-40 {
        height: 150px;
    }
}

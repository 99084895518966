.scale {
    &__title {
        font-weight: bold;
        margin-bottom: 5px;
    }

    &__input {
        display: none;
    }

    &__group {
        @include flex-a-center;
        border: 2px solid $white;
    }

    &__group--wrong {
        border: 2px solid $red;
    }

    &__label {
        display: block;
        padding: .5rem .7rem;
        font-weight: bold;
        position: relative;
        cursor: pointer;
        flex-grow: 1;
        text-align: center;
    }

    &__input:checked + .scale__label:after {
        content: '';
        display: block;
        border: 2px solid $right-border;
        background-color: transparent;
        position: absolute;
        width: 101%;
        height: 101%;
        top: -1px;
        left: -1px;
    }
}

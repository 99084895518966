a {
    color: $links;
    text-decoration: underline;

    &:hover {
        color: $links-hover;
    }
}

    .a--grey {
        color: rgba(0, 0, 0, .55);
        text-decoration: none;

        &:hover,
        &:focus {
            color: rgba(0, 0, 0, .7);
        }
    }

.ml-auto {
    margin-left: auto !important;
}

.ml-auto-lg {
    margin-left: auto !important;
}

@media (max-width: $md) {
    .ml-auto-md {
        margin-left: auto !important;
    }

    .ml-auto-lg {
        margin-left: 0;
    }
}

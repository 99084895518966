.card-body {
    padding: 1rem .6rem;
}

.a-card {
    text-decoration: none;
    color: $font-color;

    &:hover {
        text-decoration: underline;
        color: $font-color;
    }
}

@media (max-width: $sm) {
   /* .card-img-top {
    width: 50%;
    }*/
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
}

.close {
    /* float: right;
    margin-top: -30px;
     margin-right: -5px;
     */
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    cursor: pointer;
}

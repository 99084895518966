.table-sm-font {
    line-height: $line-height-table;
    font-size: $font-size-base;
}

.table-sticky {
    & tr td:first-child,
    & tr th:first-child {
            position: sticky;
            left: 0;
            z-index: 10;
            background-color: #fff;
    }
}

.table-word-break-7 {
    & tbody td:nth-child(7) {
        word-break: break-word;
        width: 220px;
    }
}

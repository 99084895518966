.list {
    & li {
        padding-bottom: .4rem;
        line-height: 1.2;
    }
}

.list-style-none {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

/*элемент списка профессий*/

.list-prof__item {
    display: flex;
    align-items: center;

    &:hover,
    & *:hover{
        cursor: pointer;
    }
}

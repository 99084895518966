.h2, h2 {
    font-size: calc(1rem + .9vw);
}

.h2--mb3 {
    margin-bottom: 1rem;
}

@media (min-width: $xl) {
    .h2, h2 {
        font-size: 1.4rem;
    }
}

.check {
    display: block;
    min-height: 1.5rem;
    height: 50%;

    &:first-child {
        border-bottom: 1px solid $grey-border;
    }

    &__input:checked~.check__label {
        background-color: $l1;
    }

    &__label {
        @include flex-a-center;
        padding: .5rem 1rem;
        background-color: $light-grey;
        height: 100%;
    }

    &__label:hover {
        background-color: $middle-grey;
    }
}

.color1,
.color2,
.color3,
.color4,
.color5,
.color6,
.color7,
.color8 {
    display: flex;
    align-items: center;

    &:before {
        content: '';
        border-radius: 50%;
        height: 9px;
        width: 9px;
        margin-right: 6px;
        background-color: $color1;
    }
}

.color2 {
    &:before {
        background-color: $color2;
    }
}

.color3 {
    &:before {
        background-color: $color3;
    }
}

.color4 {
    &:before {
        background-color: $color4;
    }
}

.color5 {
    &:before {
        background-color: $color5;
    }
}

.color6 {
    &:before {
        background-color: $color6;
    }
}

.color7 {
    &:before {
        background-color: $color7;
    }
}

.color8 {
    &:before {
        background-color: $color8;
    }
}

.card-body--diagramm {
    padding: 1rem .75rem .25rem .75rem;
}

#chart5 {
    height: 400px;
}

.container-lg {
   /* min-width: 1024px;*/
    max-width: 1440px;
    padding-right: 0;
    padding-left: 0;
}

@media (min-width: $lg) {
    .container, .container-md, .container-sm {
        max-width: 1140px;
    }
}

.mt-12per {
    margin-top: 12%;
}

.mt-lg-5 {
    margin-top: 3rem!important;
}

@media (max-width: $sm) {
    .mt-lg-5 {
        margin-top: 1rem!important;
    }
}

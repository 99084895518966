.dataTables_paginate {
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.paginate_button {
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    padding: 3px 8px;

    &:hover {
        text-decoration: underline;
    }

    &.current {
        background-color: $links;
        color: white;
    }
}

span .paginate_button {
    font-weight: normal;
}

.page-item.active .page-link {
    color: #fff;
    background-color: $links;
    border-color: $links;
}

.page-link {
    color: $links;
}


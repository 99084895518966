.h1, h1 {
    font-size: calc(1rem + 1vw);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.h1-not-mt {
    margin-top: 0;
}

@media (min-width: $xl) {
    .h1, h1 {
        font-size: 1.6rem;
    }
}


.navbar {
    & .active {
        position: relative;
    }

    & .active:after {
        content: '';
        height: 5px;
        background-color: $blue;
        position: absolute;
        width: 100%;
        bottom: - 0.8rem;
    }
}

.navbar-brand img {
   /* width: 140px;*/
}

.nav__link {
    margin-left: .7rem;

    color: $nav-links-hover;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $nav-links;
    }

    &--photo {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.navbar-light .navbar-nav .nav-link {
    color: $nav-links-hover;

    &:hover,
    &:focus {
        color: $nav-links;
    }
}

.nav-item--right {
    position: absolute;
    right: 0;
    top: 0;
}

.nav-item--users {
    position: absolute;
    top: 7px;
    right: 45px;
}

.nav-item--users.active {
    position: absolute;
}

.nav__user-photo {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid $grey-border;
}

.nav-link--photo > .dropdown-item__user-name {
    display: none;
}

/*меню боковое*/

.sidebar {
    min-width: 170px;
}

.nav-aside {
    & .nav__item {
    }

    .nav__link {
        color: $nav-links;
        text-decoration: none;
        display: flex;
        align-items: center;

        margin-left: 0;
        padding: 0.5rem 0.7rem 0.5rem 0.9rem;

        &:hover,
        &:focus {
            color: $nav-links-hover;
            font-weight: bold;
            background-color: $grey-border;
        }
    }

    & .active {
        color: $links;
        font-weight: 700;
        position: relative;
        margin-right: -1px;

        &:after {
            content: "";
            height: 100%;
            width: 7px;
            position: absolute;
            right: 0;
            top: 0;
            background-color: $links;
        }
    }

    & svg {
        width: 15px;
        margin-right: 8px;
        height: 18px;
    }
}

.sub-menu {
    & .nav__link {
        padding-left: 2.5rem;
    }
}

.nav-tabs {
    .active {
        font-weight: bold;
    }

    .nav-link {
        color: $nav-links-hover;
    }
}

@media (max-width: $lg) {
    .navbar {
        .active:after {
            content: '';
            height: 3px;
            bottom: 0;
        }
    }

    .nav__link {
        display: block;
        margin-top: 10px;
    }

    .nav-item--right {
        position: static;
    }

    .nav-link--photo {
        display: flex;
        align-items: center;
    }

    .nav-link--photo > .dropdown-item__user-name {
        display: block;
        border-bottom: none;
    }

    .nav__link--lg-btn {

    }
}

@media (min-width: $lg) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
        right: 0;
    }

    .nav__link--lg-btn {
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        color: $white;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        background-color: $blue;
        border-color: $blue-dark;
        padding: .375rem .75rem;
        font-size: 1rem;
        border-radius: .25rem;
        transition: color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;

        &:hover {
            color: $white;
            background-color: $blue-dark;
        }

        &:focus {
            box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
            color: $white;
        }
    }
}

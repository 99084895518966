.br-right,
.br-bottom {
    border-color: $dark-grey-border;
}

.br-right {
    @include border-right-1px;
}

.br-bottom {
    @include border-bottom-1px;
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-group-addon:last-child {
    border-left: 0;
}

.input-one-symbol {
    width: 40px;
}
